import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";

import { getPatchForArenaData } from "@/game-lol/utils/arena-queue-utils.mjs";
import useArenaAugments, {
  AUGMENT_TIER_FILTERS,
} from "@/game-lol/utils/useArenaAugments.jsx";
import DataTable, { DataTableLoading } from "@/shared/DataTable.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";

const FilterBar = styled("div")`
  display: flex;
  gap: var(--sp-2);
  align-items: center;
  flex-wrap: wrap;
`;

const AugmentTable = () => css`
  .augment-meta {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  .augment-name,
  .augment-description {
    display: block;
  }
  .augment-description {
    color: var(--shade1);
    height: 2.5ch;
    max-width: 30ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .augment-champs {
    display: flex;
    gap: 0.25rem;
  }
`;

function ArenaAugments() {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");
  const [selectedTier, setSelectedTier] = useState("ALL");

  const { cols, rows } = useArenaAugments({
    searchText,
    selectedTier,
  });

  const isLoading = !rows;

  if (isLoading) {
    return (
      <PageContainer>
        <PageHeader
          title={["lol:lolArenaAugments", "LoL Arena Augments"]}
          links={[
            {
              url: "/lol/arena",
              text: t("overlays:tft.augmentInfo.title", "Augment Info"),
            },
            {
              url: "/lol/arena/augments",
              text: t("common:navigation.augmentStats", "Augment Stats"),
            },
          ]}
        />
        <FilterBar>
          <TextInput
            defaultValue={searchText}
            placeholder={t("lol:searchAugments", "Search Augments")}
            onValueChange={(s) => setSearchText(s)}
          />
          <ButtonGroup>
            {AUGMENT_TIER_FILTERS.map((tier) => (
              <Button
                key={tier.key}
                active={selectedTier === tier.key}
                onClick={() => setSelectedTier(tier.key)}
              >
                {t(...tier.name)}
              </Button>
            ))}
          </ButtonGroup>
        </FilterBar>
        <DataTableLoading height={1600} />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageHeader
        title={["lol:lolArenaAugments", "LoL Arena Augments"]}
        links={[
          {
            url: "/lol/arena",
            text: t("overlays:tft.augmentInfo.title", "Augment Info"),
          },
          {
            url: "/lol/arena/augments",
            text: t("common:navigation.augmentStats", "Augment Stats"),
          },
        ]}
      />
      <FilterBar>
        <TextInput
          defaultValue={searchText}
          placeholder={t("lol:searchAugments", "Search Augments")}
          onValueChange={(s) => setSearchText(s)}
        />
        <ButtonGroup>
          {AUGMENT_TIER_FILTERS.map((tier) => (
            <Button
              key={tier.key}
              active={selectedTier === tier.key}
              onClick={() => setSelectedTier(tier.key)}
            >
              {t(...tier.name)}
            </Button>
          ))}
        </ButtonGroup>
      </FilterBar>
      <DataTable
        tallRows
        cols={cols}
        rows={rows}
        indexCol
        className={AugmentTable()}
        sortCol={1} // Tier
        sortColTiebreak={2} // Pick Rate
        sortDir="DESC"
        searchText={searchText}
        searchCol={0}
      />
    </PageContainer>
  );
}

export function meta() {
  const patch = getPatchForArenaData();

  const title = [
    "lol:meta.arenaAugments.title",
    "LoL Arena Augment Statistics",
  ];
  const description = [
    "lol:meta.arenaAugments.description",
    "All LoL Arena augment stats and tier list for the 2v2v2v2 League mode. These League Arena augment tier list stats are for patch {{patch}}.",
    { patch },
  ];

  return {
    title,
    description,
    subtitle: true,
  };
}

export default ArenaAugments;
